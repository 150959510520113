<!-- 课程介绍 -->
<template>
  <div class='description-container'>
    <img class="banner" src="../../assets/images/banner2.png" alt="">
    <div class="nva-container">
      <div class="container">
        <div class="item" v-for="item in items">
          <router-link to="path" :to="{ name: item.name }" active-class="selected" exact-active-class="">{{ item.title
            }}</router-link>
        </div>
      </div>
    </div>
    <router-view></router-view>

  </div>
</template>

<script>

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      items: [
        {
          name: "basics",
          title: "理论基础",
          // exact: true, // 激活状态是否精确匹配
        },
        {
          name: "resource",
          title: "课程资源",
          // exact: false, // 激活状态是否精确匹配
        },
        {
          name: "idea",
          title: "课程理念",
          // exact: true, // 激活状态是否精确匹配
        },
        {
          name: "target",
          title: "培养目标",
          // exact: true, // 激活状态是否精确匹配
        },
        {
          name: "frame",
          title: "课程框架",
          // exact: true, // 激活状态是否精确匹配
        },
        {
          name: "course",
          title: "发展历程",
          // exact: true, // 激活状态是否精确匹配
        },
        {
          name: "organization",
          title: "合作机构",
          // exact: true, // 激活状态是否精确匹配
        },
      ],
    };
  },
  //方法集合
  methods: {

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
}
</script>
<style lang='less' scoped>
.description-container {
  .banner {
    width: 100%;

    img {
      width: 100%;

    }
  }

  .nva-container {
    margin-bottom: 100px;
    background: #6A6A6A;
    height: 60px;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    a {
      display: block;
      width: 160px;

      width: 160px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      font-size: 20px;
      color: #FFFFFF;
    }

    .item {


      .selected {

        background: #FFA60A;
      }

    }
  }
}
</style>